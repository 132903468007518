
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import { defineComponent, ref } from "vue";
import VehicleEdit from "@/views/services/fleet/VehicleEdit.vue";
import VehicleInsurances from "@/views/services/fleet/VehicleInsurances.vue";
import VehicleControls from "@/views/services/fleet/VehicleControls.vue";
import VehicleAssignments from "@/views/services/fleet/VehicleAssignments.vue";
import VehicleMaintenances from "@/views/services/fleet/VehicleMaintenances.vue";
import VehicleMissions from "@/views/services/fleet/VehicleMissions.vue";

export default defineComponent({
    name: "services-fleet-vehicle",
    components: {
        VehicleEdit,
        VehicleInsurances,
        VehicleControls,
        VehicleAssignments,
        VehicleMaintenances,
        VehicleMissions,
    },
    setup() {
        const { t, te } = useI18n();
        const store = useStore();

        const vehicle = ref<any>({});

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };


        store.watch(
            (state, getters) => getters.getVehicle,
            (activeVehicle) => {
                vehicle.value = {
                    id: 0,
                };

                if (activeVehicle) {
                    Object.assign(vehicle.value, { id: activeVehicle.id });
                }
            });

        return {
            vehicle,
            translate,
        };
    },
});
