
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import axios, { AxiosResponse } from "axios";
import { defineComponent, onMounted, ref } from "vue";
import { Collapse, Tab } from "bootstrap";
import ApiRoutes from "@/core/config/ApiRoutes";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Vehicle from "@/views/services/fleet/Vehicle.vue";
// import EditVehicleModal from "@/components/modals/services/fleet/vehicles/EditVehicleModal.vue";
import SearchVehicleModal from "@/components/modals/services/fleet/vehicles/SearchVehicleModal.vue";

interface IPagination {
    page  : number;
    search: boolean;
    exists: number;
    total : number;
    offset: number;
}

export default defineComponent({
    name: "services-fleet-vehicles",
    props: {
        widgetClasses: String,
    },
    components: {
        Vehicle,
        SearchVehicleModal,
    },
    setup() {
        const { t, te } = useI18n();
        const store = useStore();

        let loading = ref(false);
        let activeVehicle = ref(null);
        let collapse = null as Collapse;
        const vehicles = ref([]);
        const queryParamsDefault = {
            external: 0,
            type_id: 0,
            brand_id: 0,
            model_id: 0,
            year: null,
            color_id: 0,
            fuel_id: 0,
            gearbox_id: 0,
            vin: "",
            epc: "",
            vrn: "",
            note: "",
        };
        const queryParams = ref({});
        Object.assign(queryParams.value, queryParamsDefault);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const queryParamsLabels = {
            external  : translate("vehicleExternalLabel"),
            type_id   : translate("vehicleTypeIdLabel"),
            brand_id  : translate("vehicleBrandIdLabel"),
            model_id  : translate("vehicleModelIdLabel"),
            year      : translate("vehicleYearLabel"),
            color_id  : translate("vehicleColorlIdLabel"),
            fuel_id   : translate("vehicleFuelIdLabel"),
            gearbox_id: translate("vehicleGearboxIdLabel"),
            vin       : translate("vehicleVinLabel"),
            vrn       : translate("vehicleVrnLabel"),
            note      : translate("vehicleNoteLabel"),
        };
        const pagination = ref<IPagination>({
            page  : 1,
            search: false,
            exists: 0,
            total : 0,
            offset: 10,
        });

        store.watch(
            (state, getters) => getters.getRefresh,
            (doRefresh) => {

                if (doRefresh > 0) {

                    if (doRefresh == 1) {
                        collapse.hide();
                    }

                    refresh();
                }
            });

        const goPage = (page: number) => {
            collapse.hide();

            pagination.value.page = page;

            getVehicles();
        };

        const setOffset = (event) => {
            collapse.hide();

            pagination.value.offset = parseInt(event.target.value);

            getVehicles();
        };

        const refresh = () => {
            pagination.value.page = 1;
            pagination.value.total = 0;
            pagination.value.offset = 10;

            getVehicles();

            store.dispatch(Actions.REFRESH_VEHICLES, 0);
        };

        const scrollTo = (element: string) => {

            document.getElementById(element)?.scrollIntoView({
                behavior: 'smooth'
            });
        };

        const create = () => {
            store.dispatch(Actions.SET_VEHICLE, null);

            collapse.show();

            (new Tab(document.querySelector('#kt_tab_button_vehicle_1'))).show();
        };

        const edit = (vehicle) => {
            store.dispatch(Actions.SET_VEHICLE, vehicle);

            collapse.show();

            (new Tab(document.querySelector('#kt_tab_button_vehicle_1'))).show();
        };

        const confirmRemove = (vehicle_id: number) => {
            collapse.hide();

            Swal.fire({
                title: translate("warningHeading"),
                text: translate("vehicleConfirmDelete"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: translate("confirm"),
                cancelButtonText: translate("cancel"),
                customClass: {
                    confirmButton: "btn btn-danger",
                    cancelButton: "btn btn-secondary",
                },
            }).then((result) => {

                if (result.isConfirmed) {
                    remove(vehicle_id);
                }
            })
        }

        const remove = (vehicle_id: number) => {
            collapse.hide();

            axios.delete(`${ApiRoutes.vehicles.delete}/${vehicle_id}`)
                .then((response: AxiosResponse) => {

                    if (response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            refresh();
                        });
                    } else {

                        Swal.fire({
                            text: response.data.message,
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const removeQueryParam = (name: string): void => {
            collapse.hide();

            if (queryParams.value && typeof queryParams.value[name] !== 'undefined') {
                queryParams.value[name] = queryParamsDefault[name];

                refresh();
            }
        };

        const getVehicles = async () => {
            loading.value = true;

            activeVehicle.value = null;

            const params = {};
            Object.assign(params, queryParams.value, pagination.value);

            await axios.get(ApiRoutes.vehicles.index, { params: params })
                .then((response: AxiosResponse) => {

                    loading.value = false;

                    pagination.value.search = response.data.search;
                    pagination.value.exists = response.data.exists;
                    pagination.value.total = response.data.total;

                    vehicles.value = response.data.rows;
                }).catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const searchVehicles = (params) => {
            collapse.hide();

            Object.assign(queryParams.value, params);

            refresh();
        };

        onMounted(() => {
            getVehicles();

            const collapseElement = document.getElementById('kt_collapse_vehicle');

            collapse = new Collapse(collapseElement, {
                toggle: false
            });

            collapseElement?.addEventListener('shown.bs.collapse', function () {
                scrollTo("kt_collapse_vehicle");
            });

            setCurrentPageBreadcrumbs(
                translate("vehicleManagementHeading"), [
                    translate("servicesHeading"),
                    translate("fleetHeading")
                ]
            );
        });

        return {
            translate,
            vehicles,
            queryParams,
            queryParamsLabels,
            queryParamsDefault,
            removeQueryParam,
            searchVehicles,
            pagination,
            activeVehicle,
            loading,
            refresh,
            goPage,
            create,
            edit,
            confirmRemove,
            setOffset,
        };
    },
});
