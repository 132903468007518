
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import moment from 'moment';
import axios, { AxiosResponse } from "axios";
import { defineComponent, ref, onMounted,onActivated } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import jQuery from 'jquery';
import 'select2/dist/js/select2.full.min';
import 'select2/dist/css/select2.min.css';

import { checkField, resetField, enableSubmitButton, disableSubmitButton } from "@/core/helpers/form";
import FieldError from "@/components/modals/shared/FieldError.vue";

import StarRating from 'vue-star-rating';





export default defineComponent({
    name: "validate-vehicle-maintenance-modal",
    emit: ["refreshData"],
    props: {
        vehicleMaintenance: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
        Datepicker,
        StarRating,
        FieldError,
    },
    data () {
      return {
        // value: null,
        // empl: ['list', 'of', 'options']
      }
    },

    setup(props, { emit }) {
        const { t, te } = useI18n();

        let modal = ref();

        let errors: any = ref();


        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const validatevehicleMaintModalRef = ref<null | HTMLElement>(null);
        const validateVehicleMaintDefault = {
            id                     :null,
            date_end               :"",
            eval_maint             :0,
            spare_part_id          :" ",
            is_validated           :'0',
            nbr_maint              :null,
            cost_maint             :Number,
            status                 :Number,


        };
        const validateVehicleMaintData = ref<any>({});
        Object.assign(validateVehicleMaintData.value, validateVehicleMaintDefault);


        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };



        const validationSchema = Yup.object().shape({
            // date: Yup.string().required().label(translate("orderMaintenanceBeginDateLabel")),
            // date_end: Yup.string().required().label(translate("orderMaintenanceEndDateLabel")),
            // description: Yup.string().label(translate("requestMaintenanceDescriptionLabel")),

        });


        const submit = (values, actions) => {
            errors.value = null;

            if (!submitButtonRef.value) {
                return;
            }


            if (validateVehicleMaintData.value.is_validated == '1') {
                validateVehicleMaintData.value.status = 3;

            }
            else{
                validateVehicleMaintData.value.status = 1;
            }


            // Disable button
            // submitButtonRef.value.disabled = true;
            // Activate indicator
            // submitButtonRef.value.setAttribute("data-kt-indicator", "on");

            validateVehicleMaintData.value.id = props.vehicleMaintenance.id;



            validateVehicleMaintData.value.date_end = dateFormat(validateVehicleMaintData.value.date_end );


            if (validateVehicleMaintData.value.spare_part_id) { validateVehicleMaintData.value.spare_part_id = validateVehicleMaintData.value.spare_part_id.id; }

            if (validateVehicleMaintData.value.nbr_maint > 0) {
                validateVehicleMaintData.value.cost_maint = validateVehicleMaintData.value.nbr_maint * 2000 + 15000;

            }else{
                validateVehicleMaintData.value.cost_maint = null;

            }




            axios.post(ApiRoutes.vehicleMaintenances.validate,validateVehicleMaintData.value )
                .then((response: AxiosResponse) => {

                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false;

                        submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    }

                    if (response.data && response.data.success) {


                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            hideModal(validatevehicleMaintModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    // if (submitButtonRef.value) {
                    //     submitButtonRef.value.disabled = false;

                    //     submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    // }
                    if (error.response.status == 422) {
                        errors.value = error.response.data.errors;
                    } else {
                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                    }

                });


        };



        const dateFormat = (value) => {
            return moment(value).format('YYYY-MM-DD');
        };

        const updateState = async () => {
            validateVehicleMaintData.value.is_validated ='1';

            const saveBtn: HTMLElement = document.getElementById('kt_modal_validate_vehicle_maintenance_submit') as HTMLElement
            saveBtn.click();

        };





        onMounted(() => {

             modal.value = document.getElementById('kt_modal_validate_vehicle_maintenance');

            if (modal.value) modal.value.addEventListener('hidden.bs.modal', function(event) {
                Object.assign(validateVehicleMaintData.value, validateVehicleMaintDefault);
            });

            if (modal.value) modal.value.addEventListener('shown.bs.modal', function() {

                if (props.vehicleMaintenance) {

                    // validateVehicleMaintData.value.nbr_maint = props.vehicleMaintenance.cost_maint / 2000 - 15000;

                    if (props.vehicleMaintenance.status == 3) {
                         validateVehicleMaintData.value.is_validated = '1';

                    }

                    // Fill Intra fields
                    Object.keys(props.vehicleMaintenance).forEach((key) => {
                        if (validateVehicleMaintDefault.hasOwnProperty(key)) validateVehicleMaintData.value[key] = props.vehicleMaintenance[key];
                    });
                }
            });


            // loadJquery for employee_id
            jQuery("#validateVehicleMaintSparePartId").select2({
                dropdownParent: modal.value,
                minimumInputLength: 1,
                placeholder: translate('search') + '...',
                ajax: {
                    dataType: 'json',
                    delay: 250,
                    url: ApiRoutes.employees.employees,
                    data: function (params) {
                        return {
                            q: params.term,
                            code:'D01',
                            // exclude: requestMaintenanceOrderData.value.supplier_employee.map(a => a.id),
                        };
                    },
                    processResults: function (data, params) {
                        return {
                            results: data
                        };
                    },
                    cache: false
                }

            }).on('select2:select', function(e){
                let emp = e.params.data;
                validateVehicleMaintData.value.spare_part_id = emp;
                let data = {
                    id              : emp.id,
                    heading         : null,
                    name            : emp.last_name,
                };

                // if (emp.last_name && emp.last_name.length) {

                    // requestMaintenanceOrderData.value.supplier_employee.push(Object.assign({}, data, {heading: `${emp.last_name}`, attributes_array: emp.last_name}));

                // } else {
                    validateVehicleMaintData.value.spare_part_id.push(data);
                // }

                jQuery("#validateVehicleMaintSparePartId").val('').trigger('change');
            });

            // end load jQuery for employee_id




        });


        return {
            errors,
            resetField,
            checkField,

            translate,
            modal,
            validateVehicleMaintData,
            updateState,
            validationSchema,
            submit,
            submitButtonRef,
            validatevehicleMaintModalRef,
            dateFormat,
        };
    },
});
