
import { useI18n } from "vue-i18n/index";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";
import axios from "axios";

export default defineComponent({
    name: "search-vehicle-modal",
    emit: ["refreshData"],
    props: {
        params: null,
        getParams: Function
    },
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        let types = ref([]);
        let brands = ref([]);
        let colors = ref([]);
        let models = ref([]);
        let fuels = ref([]);
        let gearboxes = ref([]);
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const searchVehicleModalRef = ref<null | HTMLElement>(null);
        const initialValues = {
            type_id   : 0,
            brand_id  : 0,
            model_id  : 0,
            external  : false,
            color_id  : 0,
            fuel_id   : 0,
            gearbox_id: 0,
            vin       : "",
            epc       : "",
            year      : null,
            vrn       : "",
            note      : "",
        };
        const queryParams = ref<any>({});
        Object.assign(queryParams.value, initialValues);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const submit = (values, actions) => {

            if (!submitButtonRef.value) {
                return;
            }

            if (props.getParams) props.getParams(queryParams.value);

            hideModal(searchVehicleModalRef.value);
        };

        const getTypes = async () => {
            const response = await axios.get(ApiRoutes.vehicles.types);

            types.value = response.data;
        };

        const getBrands = async () => {
            const response = await axios.get(ApiRoutes.vehicles.brands);

            brands.value = response.data;
        };

        const getModels = async () => {
            const response = await axios.get(ApiRoutes.vehicles.models, { params: { brand_id: queryParams.value.brand_id } });

            models.value = response.data;
        };

        const getFuels = async () => {
            const response = await axios.get(ApiRoutes.vehicles.fuels);

            fuels.value = response.data;
        };

        const getColors = async () => {
            const response = await axios.get(ApiRoutes.vehicles.colors);

            colors.value = response.data;
        };

        const getGearboxes = async () => {
            const response = await axios.get(ApiRoutes.vehicles.gearboxes);

            gearboxes.value = response.data;
        };

        onMounted(() => {
            // getTypes();
            // getBrands();
            // getColors();
            // getFuels();
            // getGearboxes();

            const modal = document.getElementById('kt_modal_search_vehicle');

            // if (modal) modal.addEventListener('hidden.bs.modal', function() {
            // });

            if (modal) modal.addEventListener('shown.bs.modal', function() {

                if (props.params) {
                    // Fill Intra fields
                    Object.keys(props.params).forEach((key) => {

                        if (initialValues.hasOwnProperty(key)) {
                            if (typeof initialValues[key] == 'boolean') queryParams.value[key] = Boolean(props.params[key]);
                            else queryParams.value[key] = props.params[key];
                        }
                    });
                }
            });
        });

        return {
            translate,
            types,
            brands,
            models,
            colors,
            fuels,
            gearboxes,
            queryParams,
            getModels,
            submit,
            submitButtonRef,
            searchVehicleModalRef,
        };
    },
});
