
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import axios, { AxiosResponse } from "axios";
import moment from 'moment';
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";

export default defineComponent({
    name: "services-vehicle-insurance-edit-modal",
    emit: ["refreshData"],
    props: {
        vehicleInsurance: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();

        let companies = ref([]);
        let isUpdate = false as boolean;
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editVehicleInsuranceModalRef = ref<null | HTMLElement>(null);
        const vehicleInsuranceDefaultData = {
            id: null,
            vehicle_id: 0,
            company_id: 0,
            agency_name: "",
            agency_code: "",
            policy_number: "",
            begins: "",
            ends: "",
            amount: 0,
        }
        const vehicleInsuranceData = ref<any>({});
        Object.assign(vehicleInsuranceData.value, vehicleInsuranceDefaultData);

        const dateFormat = (value: string, format: string = 'YYYY-MM-DD'): string => {
            return moment(value).format(format);
        };

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const validationSchema = Yup.object().shape({
            company_id: Yup.number().min(1).label(translate("vehicleInsuranceCompanyIdLabel")),
            agency_name: Yup.string().required().label(translate("vehicleInsuranceAgencyNameLabel")),
            agency_code: Yup.string().required().label(translate("vehicleInsuranceAgencyCodeLabel")),
            policy_number: Yup.string().required().label(translate("vehicleInsurancePolicyNumberLabel")),
            begins: Yup.date().nullable().label(translate("vehicleInsuranceBeginsLabel")),
            ends: Yup.date().nullable().label(translate("vehicleInsuranceEndsLabel")),
            amount: Yup.number().required().min(1).label(translate("vehicleInsuranceAmountLabel")),
        });

        const getCompanies = async () => {
            const response = await axios.get(ApiRoutes.vehicleInsurances.companies);

            companies.value = response.data;
        };

        const submit = (values, actions) => {
            if (!submitButtonRef.value) {
                return;
            }

            // Disable button
            submitButtonRef.value.disabled = true;
            // Activate indicator
            submitButtonRef.value.setAttribute("data-kt-indicator", "on");

            if (isUpdate && props.vehicleInsurance.id) Object.assign(vehicleInsuranceData.value, {id: props.vehicleInsurance.id});

            axios.post(ApiRoutes.vehicleInsurances.updateOrCreate, vehicleInsuranceData.value)
                .then((response: AxiosResponse) => {

                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false;

                        submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    }

                    if (response.data && response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            hideModal(editVehicleInsuranceModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false;

                        submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    }

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        onMounted(() => {
            getCompanies();

            const modal = document.getElementById('kt_modal_edit_vehicle_insurance');

            if (modal) modal.addEventListener('hidden.bs.modal', function() {
                Object.assign(vehicleInsuranceData.value, vehicleInsuranceDefaultData);
            });

            if (modal) modal.addEventListener('shown.bs.modal', function() {
                isUpdate = false;

                if (props.vehicleInsurance) {
                    isUpdate = true;

                    props.vehicleInsurance.begins = dateFormat(props.vehicleInsurance.begins);
                    props.vehicleInsurance.ends = dateFormat(props.vehicleInsurance.ends);

                    Object.assign(vehicleInsuranceData.value, props.vehicleInsurance);
                }
            });
        });

        return {
            translate,
            dateFormat,
            companies,
            vehicleInsuranceData,
            validationSchema,
            submit,
            submitButtonRef,
            editVehicleInsuranceModalRef,
        };
    },
});
