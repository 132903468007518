import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "kt_collapse_vehicle",
  class: "card collapse mt-10"
}
const _hoisted_2 = { class: "card-header card-header-stretch" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "card-toolbar" }
const _hoisted_5 = { class: "nav nav-tabs nav-line-tabs nav-stretch fs-5 border-0" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = {
  class: "nav-link active",
  href: "#kt_tab_pane_vehicle_1",
  id: "kt_tab_button_vehicle_1",
  "data-bs-toggle": "tab"
}
const _hoisted_8 = { class: "nav-item" }
const _hoisted_9 = ["aria-disabled"]
const _hoisted_10 = { class: "nav-item" }
const _hoisted_11 = ["aria-disabled"]
const _hoisted_12 = { class: "nav-item" }
const _hoisted_13 = ["aria-disabled"]
const _hoisted_14 = { class: "nav-item" }
const _hoisted_15 = ["aria-disabled"]
const _hoisted_16 = { class: "nav-item" }
const _hoisted_17 = ["aria-disabled"]
const _hoisted_18 = { class: "card-body" }
const _hoisted_19 = {
  class: "tab-content",
  id: "vehicleTabs"
}
const _hoisted_20 = {
  class: "tab-pane fade show active",
  id: "kt_tab_pane_vehicle_1",
  role: "tabpanel"
}
const _hoisted_21 = {
  class: "tab-pane fade",
  id: "kt_tab_pane_vehicle_2",
  role: "tabpanel"
}
const _hoisted_22 = {
  class: "tab-pane fade",
  id: "kt_tab_pane_vehicle_3",
  role: "tabpanel"
}
const _hoisted_23 = {
  class: "tab-pane fade",
  id: "kt_tab_pane_vehicle_4",
  role: "tabpanel"
}
const _hoisted_24 = {
  class: "tab-pane fade",
  id: "kt_tab_pane_vehicle_5",
  role: "tabpanel"
}
const _hoisted_25 = {
  class: "tab-pane fade",
  id: "kt_tab_pane_vehicle_6",
  role: "tabpanel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VehicleEdit = _resolveComponent("VehicleEdit")!
  const _component_VehicleInsurances = _resolveComponent("VehicleInsurances")!
  const _component_VehicleControls = _resolveComponent("VehicleControls")!
  const _component_VehicleMaintenances = _resolveComponent("VehicleMaintenances")!
  const _component_VehicleAssignments = _resolveComponent("VehicleAssignments")!
  const _component_VehicleMissions = _resolveComponent("VehicleMissions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.translate("vehicleEditHeading")), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("ul", _hoisted_5, [
          _createElementVNode("li", _hoisted_6, [
            _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.translate("vehicleInfosHeading")), 1)
          ]),
          _createElementVNode("li", _hoisted_8, [
            _createElementVNode("a", {
              class: _normalizeClass(["nav-link", {'disabled': !_ctx.vehicle?.id}]),
              href: "#kt_tab_pane_vehicle_2",
              id: "kt_tab_button_vehicle_2",
              "data-bs-toggle": "tab",
              "aria-disabled": (_ctx.vehicle?.id) ? true : false
            }, _toDisplayString(_ctx.translate("vehicleInsurancesHeading")), 11, _hoisted_9)
          ]),
          _createElementVNode("li", _hoisted_10, [
            _createElementVNode("a", {
              class: _normalizeClass(["nav-link", {'disabled': !_ctx.vehicle?.id}]),
              href: "#kt_tab_pane_vehicle_3",
              id: "kt_tab_button_vehicle_3",
              "data-bs-toggle": "tab",
              "aria-disabled": (_ctx.vehicle?.id) ? true : false
            }, _toDisplayString(_ctx.translate("vehicleControlsHeading")), 11, _hoisted_11)
          ]),
          _createElementVNode("li", _hoisted_12, [
            _createElementVNode("a", {
              class: _normalizeClass(["nav-link", {'disabled': !_ctx.vehicle?.id}]),
              href: "#kt_tab_pane_vehicle_4",
              id: "kt_tab_button_vehicle_4",
              "data-bs-toggle": "tab",
              "aria-disabled": (_ctx.vehicle?.id) ? true : false
            }, _toDisplayString(_ctx.translate("vehicleRepairsHeading")), 11, _hoisted_13)
          ]),
          _createElementVNode("li", _hoisted_14, [
            _createElementVNode("a", {
              class: _normalizeClass(["nav-link", {'disabled': !_ctx.vehicle?.id}]),
              href: "#kt_tab_pane_vehicle_5",
              id: "kt_tab_button_vehicle_5",
              "data-bs-toggle": "tab",
              "aria-disabled": (_ctx.vehicle?.id) ? true : false
            }, _toDisplayString(_ctx.translate("vehicleAssignmentsHeading")), 11, _hoisted_15)
          ]),
          _createElementVNode("li", _hoisted_16, [
            _createElementVNode("a", {
              class: _normalizeClass(["nav-link", {'disabled': !_ctx.vehicle?.id}]),
              href: "#kt_tab_pane_vehicle_6",
              id: "kt_tab_button_vehicle_6",
              "data-bs-toggle": "tab",
              "aria-disabled": (_ctx.vehicle?.id) ? true : false
            }, _toDisplayString(_ctx.translate("vehicleMissionsHeading")), 11, _hoisted_17)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          _createVNode(_component_VehicleEdit)
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createVNode(_component_VehicleInsurances)
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createVNode(_component_VehicleControls)
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createVNode(_component_VehicleMaintenances)
        ]),
        _createElementVNode("div", _hoisted_24, [
          _createVNode(_component_VehicleAssignments)
        ]),
        _createElementVNode("div", _hoisted_25, [
          _createVNode(_component_VehicleMissions)
        ])
      ])
    ])
  ]))
}