
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import moment from 'moment';
import axios, { AxiosResponse } from "axios";
import { defineComponent, onMounted, ref } from "vue";
import { Modal } from "bootstrap";
import ApiRoutes from "@/core/config/ApiRoutes";
import Swal from "sweetalert2/dist/sweetalert2.js";
import EditVehicleAssignmentModal from "@/components/modals/services/fleet/vehicles/EditVehicleAssignmentModal.vue";

interface IPagination {
    page  : number;
    search: boolean;
    exists: number;
    total : number;
    offset: number;
}

export default defineComponent({
    name: "services-vehicle-assignments",
    components: {
        EditVehicleAssignmentModal,
    },
    setup() {
        const { t, te } = useI18n();
        const store = useStore();

        let loading = ref(false);
        let activeVehicle = ref({
            id: null
        });
        let activeVehicleAssignment = ref<any>({});
        const vehicleAssignments = ref([]);

        const dateFormat = (value) => {
            return moment(value).format('DD-MM-YYYY');
        };

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const pagination = ref<IPagination>({
            page  : 1,
            search: false,
            exists: 0,
            total : 0,
            offset: 10,
        });

        store.watch(
            (state, getters) => getters.getVehicle,
            (vehicle) => {

                if (vehicle) {

                    Object.assign(activeVehicle.value, vehicle);
                    Object.assign(activeVehicleAssignment.value, { vehicle_id: vehicle.id });
                }

                getVehicleAssignments();
            });

        const goPage = (page: number) => {
            pagination.value.page = page;

            getVehicleAssignments();
        };

        const setOffset = (event) => {
            pagination.value.offset = parseInt(event.target.value);

            getVehicleAssignments();
        };

        const refresh = () => {
            pagination.value.page = 1;
            pagination.value.total = 0;
            pagination.value.offset = 10;

            getVehicleAssignments();
        };

        const create = () => {
            activeVehicleAssignment.value = {};
            Object.assign(activeVehicleAssignment.value, { vehicle_id: activeVehicle.value.id });

            const modal = new Modal(document.getElementById('kt_modal_edit_vehicle_assignment'));
            modal.show();
        };

        const edit = (vehicleAssignment) => {
            activeVehicleAssignment.value = vehicleAssignment;
            const modal = new Modal(document.getElementById('kt_modal_edit_vehicle_assignment'));
            modal.show();
        };

        const confirmRemove = (vehicle_control_id: number) => {

            Swal.fire({
                title: translate("warningHeading"),
                text: translate("vehicleAssignmentConfirmDelete"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: translate("confirm"),
                cancelButtonText: translate("cancel"),
                customClass: {
                    confirmButton: "btn btn-danger",
                    cancelButton: "btn btn-secondary",
                },
            }).then((result) => {

                if (result.isConfirmed) {
                    remove(vehicle_control_id);
                }
            })
        }

        const remove = (vehicle_control_id: number) => {

            axios.delete(`${ApiRoutes.vehicleAssignments.delete}/${vehicle_control_id}`)
                .then((response: AxiosResponse) => {

                    if (response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            refresh();
                        });

                    } else {

                        Swal.fire({
                            text: response.data.message,
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const getVehicleAssignments = async () => {
            loading.value = true;

            const params = {vehicle_id: activeVehicle.value.id};
            Object.assign(params, pagination.value);

            await axios.get(ApiRoutes.vehicleAssignments.index, { params: params })
                .then((response: AxiosResponse) => {

                    loading.value = false;

                    pagination.value.search = response.data.search;
                    pagination.value.exists = response.data.exists;
                    pagination.value.total = response.data.total;

                    vehicleAssignments.value = response.data.rows;
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        onMounted(() => {
        });

        return {
            translate,
            dateFormat,
            vehicleAssignments,
            pagination,
            activeVehicleAssignment,
            loading,
            refresh,
            goPage,
            create,
            edit,
            confirmRemove,
            setOffset,
        };
    },
});
