
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import moment from 'moment';
import axios, { AxiosResponse } from "axios";
import { hideModal } from "@/core/helpers/dom";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiRoutes from "@/core/config/ApiRoutes";

interface IPagination {
    page  : number;
    search: boolean;
    exists: number;
    total : number;
    offset: number;
}

export default defineComponent({
    name: "referencials-product-adjustment-edit-modal",
    emit: ["refreshData"],
    props: {
        vehicleDamage: null,
        vehicleInsurance: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();

        let loading = ref(false);
        let isUpdate = false as boolean;

        let modal = ref();

        let conditions = ref([]);
        const vehicleDamages = ref([]);
        const vehicleInsurance = ref([]);
        const pagination = ref<IPagination>({
            page  : 1,
            search: false,
            exists: 0,
            total : 0,
            offset: 10,
        });

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editVehicleDamageModalRef = ref<null | HTMLElement>(null);
        const vehicleDamageDefault = {
            id           : null,
            insurance_id : null,
            vehicle_id   : null,
            description  : "",
            condition_id : null,
            location     : "",
            location_from: "",
            location_to  : "",
        };
        const vehicleDamageData = ref<any>({});
        Object.assign(vehicleDamageData.value, vehicleDamageDefault);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const dateFormat = (value) => {
            return moment(value).format('DD-MM-YYYY');
        };

        const validationSchema = Yup.object().shape({
        });

        const enableSubmitButton = () => {

            if (submitButtonRef.value) {
                submitButtonRef.value.disabled = false;

                submitButtonRef.value?.removeAttribute("data-kt-indicator");
            }
        };

        const disableSubmitButton = () => {
            if (!submitButtonRef.value) {
                return;
            }

            // Disable button
            submitButtonRef.value.disabled = true;
            // Activate indicator
            submitButtonRef.value.setAttribute("data-kt-indicator", "on");
        };

        const submit = (values, actions) => {
            disableSubmitButton();

            if (isUpdate && props.vehicleDamage.id) vehicleDamageData.value.id = props.vehicleDamage.id;

            axios.post(ApiRoutes.vehicleDamages.updateOrCreate, vehicleDamageData.value)
                .then((response: AxiosResponse) => {

                    enableSubmitButton();

                    if (response.data && response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            // hideModal(editVehicleDamageModalRef.value);

                            let button = document.getElementById('kt_tab_button_vehicle_damage_2');
                            if (button) button.click();

                            Object.assign(vehicleDamageData.value, vehicleDamageDefault);

                            refresh();

                            // emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    enableSubmitButton();

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const confirmRemove = (vehicle_damage_id: number) => {

            Swal.fire({
                title: translate("warningHeading"),
                text: translate("vehicleControlConfirmDelete"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: translate("confirm"),
                cancelButtonText: translate("cancel"),
                customClass: {
                    confirmButton: "btn btn-danger",
                    cancelButton: "btn btn-secondary",
                },
            }).then((result) => {

                if (result.isConfirmed) {
                    remove(vehicle_damage_id);
                }
            })
        }

        const remove = (vehicle_damage_id: number) => {

            axios.delete(`${ApiRoutes.vehicleDamages.delete}/${vehicle_damage_id}`)
                .then((response: AxiosResponse) => {

                    if (response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            refresh();
                        });

                    } else {

                        Swal.fire({
                            text: response.data.message,
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const goPage = (page: number) => {
            pagination.value.page = page;

            getVehicleDamages();
        };

        const setOffset = (event) => {
            pagination.value.offset = parseInt(event.target.value);

            getVehicleDamages();
        };

        const refresh = () => {
            pagination.value.page = 1;
            pagination.value.total = 0;
            pagination.value.offset = 10;

            getVehicleDamages();
        };

        const getConditions = async () => {
            const response = await axios.get(ApiRoutes.vehicleDamages.conditions);

            conditions.value = response.data;
        };

        const getVehicleDamages = async () => {
            loading.value = true;

            const params = {
                vehicle_id  : props.vehicleInsurance.vehicle_id,
                insurance_id: props.vehicleInsurance.id
            };
            Object.assign(params, pagination.value);

            await axios.get(ApiRoutes.vehicleDamages.index, { params: params })
                .then((response: AxiosResponse) => {

                    loading.value = false;

                    pagination.value.search = response.data.search;
                    pagination.value.exists = response.data.exists;
                    pagination.value.total = response.data.total;

                    vehicleDamages.value = response.data.rows;
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        onMounted(() => {
            getConditions();

            modal.value = document.getElementById('kt_modal_edit_vehicle_damage');

            if (modal.value) modal.value.addEventListener('hidden.bs.modal', function(event) {
                Object.assign(vehicleDamageData.value, vehicleDamageDefault);
            });

            if (modal.value) modal.value.addEventListener('shown.bs.modal', async function() {
                isUpdate = false;

                vehicleDamageData.value = Object.assign(vehicleDamageData.value, {insurance_id : props.vehicleInsurance.id, vehicle_id : props.vehicleInsurance.vehicle_id});

                if (props.vehicleDamage) {
                    isUpdate = true;

                    // Fill vehicleDamage fields
                    Object.keys(props.vehicleDamage).forEach((key) => {
                        if (vehicleDamageDefault.hasOwnProperty(key)) vehicleDamageData.value[key] = props.vehicleDamage[key];
                    });
                }

                getVehicleDamages();
            });
        });

        return {
            translate,
            modal,
            ApiRoutes,
            refresh,
            loading,
            conditions,
            confirmRemove,
            vehicleInsurance,
            vehicleDamages,
            dateFormat,
            pagination,
            setOffset,
            goPage,
            vehicleDamageData,
            validationSchema,
            submit,
            submitButtonRef,
            editVehicleDamageModalRef,
        };
    },
});
