
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import axios, { AxiosResponse } from "axios";
import moment from 'moment';
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";

export default defineComponent({
    name: "services-vehicle-control-edit-modal",
    emit: ["refreshData"],
    props: {
        vehicleControl: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();

        let agencies = ref([]);
        let checkpoints = ref([]);
        let isUpdate = false as boolean;
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editVehicleControlModalRef = ref<null | HTMLElement>(null);
        const vehicleControlDefaultData = {
            id                      : null,
            vehicle_id              : 0,
            agency_id               : 0,
            code                    : "",
            date                    : "",
            expires                 : "",
            checkpoints             : [],
            reinspection            : 0,
            reinspection_checkpoints: [],
            reinspection_note       : "",
        }
        const vehicleControlData = ref<any>({});
        Object.assign(vehicleControlData.value, vehicleControlDefaultData);

        const dateFormat = (value: string, format: string = 'YYYY-MM-DD'): string => {
            return moment(value).format(format);
        };

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const validationSchema = Yup.object().shape({
            agency_id: Yup.number().required().min(1).label(translate("vehicleControlAgencyIdLabel")),
            code: Yup.string().required().label(translate("vehicleControlCodeLabel")),
            date: Yup.date().required().label(translate("vehicleControlDateLabel")),
            expires: Yup.date().required().label(translate("vehicleControlExpiresLabel")),
            checkpoints: Yup.array().nullable().label(translate("vehicleControlCheckpointsLabel")),
            reinspection: Yup.number().nullable().label(translate("vehicleControlReinspectionLabel")),
            reinspection_checkpoints: Yup.array().nullable().label(translate("vehicleControlReinspectionCheckpointsLabel")),
            reinspection_note: Yup.string().label(translate("vehicleControlReinspectionNoteLabel")),
        });

        const getAgencies = async () => {
            const response = await axios.get(ApiRoutes.vehicleControls.agencies);

            agencies.value = response.data;
        };

        const getCheckpoints = async () => {
            const response = await axios.get(ApiRoutes.vehicleControls.checkpoints);

            checkpoints.value = response.data;
        };

        const submit = (values, actions) => {
            if (!submitButtonRef.value) {
                return;
            }

            // Disable button
            submitButtonRef.value.disabled = true;
            // Activate indicator
            submitButtonRef.value.setAttribute("data-kt-indicator", "on");

            if (isUpdate && props.vehicleControl.id) Object.assign(vehicleControlData.value, {id: props.vehicleControl.id});

            axios.post(ApiRoutes.vehicleControls.updateOrCreate, vehicleControlData.value)
                .then((response: AxiosResponse) => {

                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false;

                        submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    }

                    if (response.data && response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            hideModal(editVehicleControlModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false;

                        submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    }

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        onMounted(() => {
            getAgencies();
            getCheckpoints();

            const modal = document.getElementById('kt_modal_edit_vehicle_control');

            if (modal) modal.addEventListener('hidden.bs.modal', function() {
                Object.assign(vehicleControlData.value, vehicleControlDefaultData);
            });

            if (modal) modal.addEventListener('shown.bs.modal', function() {
                isUpdate = false;

                if (props.vehicleControl) {
                    isUpdate = true;

                    props.vehicleControl.date = dateFormat(props.vehicleControl.date);
                    props.vehicleControl.expires = dateFormat(props.vehicleControl.expires);

                    Object.keys(props.vehicleControl).forEach((key) => {
                        if (vehicleControlDefaultData.hasOwnProperty(key)) vehicleControlData.value[key] = props.vehicleControl[key];
                    });
                }
            });
        });

        return {
            translate,
            dateFormat,
            agencies,
            checkpoints,
            vehicleControlData,
            validationSchema,
            submit,
            submitButtonRef,
            editVehicleControlModalRef,
        };
    },
});
