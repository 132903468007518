
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import axios, { AxiosResponse } from "axios";
import moment from 'moment';
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";

import jQuery from 'jquery';
import 'select2/dist/js/select2.full.min';
import 'select2/dist/css/select2.min.css';

import { checkField, resetField, enableSubmitButton, disableSubmitButton } from "@/core/helpers/form";
import FieldError from "@/components/modals/shared/FieldError.vue";

export default defineComponent({
    name: "services-vehicle-control-edit-modal",
    emit: ["refreshData"],
    props: {
        vehicleMaintenance: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
        FieldError,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();

        let types = ref([]);
        let breakdowns = ref([]);
        let modal = ref();
        let analyticAccounts = ref([]);
        let suppliers = ref([]);

        let errors: any = ref();


        let isUpdate = false as boolean;
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editVehicleMaintenanceModalRef = ref<null | HTMLElement>(null);
        const vehicleMaintenanceDefault = {
            id                     : null,
            vehicle_id             : null,
            date_begin             : "",
            type_id                : 0,
            supplier_id            : 0,
            breakdown_id           : 0,
            employee_id            : 0,
            analytic_account       : "",
            analytic_account_type  :0,
            status                 :-1,
            execution_type         :1,
            supplier_employee_id   :0,
        }
        const vehicleMaintenanceData = ref<any>({});
        Object.assign(vehicleMaintenanceData.value, vehicleMaintenanceDefault);



        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const validationSchema = Yup.object().shape({
            // itinerary_id: Yup.number().min(1).required().label(translate("vehicleMissionItineraryIdLabel")),
            // driver_id: Yup.number().min(1).required().label(translate("vehicleMissionDriverIdLabel")),
            // external: Yup.boolean().label(translate("vehicleMissionExternalLabel")),
            // structure_type: Yup.number().required().min(1).label(translate("vehicleMissionStructureTypeLabel")),
            // structure_code: Yup.string().nullable().label(translate("vehicleMissionStructureCodeLabel")),
            // supplier_intra_id: Yup.number().nullable().label(translate("vehicleMissionSupplierIntraIdLabel")),
            // begins: Yup.date().required().label(translate("vehicleMissionBeginsLabel")),
            // ends: Yup.date().label(translate("vehicleMissionEndsLabel")),
        });







        // get maintenance types
        const getTypes = async () => {
            const response = await axios.get(ApiRoutes.vehicleMaintenances.types);

            types.value = response.data;
        };





        // get maintenance breakdowns
        const getBreakdowns = async () => {
            const response = await axios.get(ApiRoutes.vehicleMaintenances.breakdowns);

            breakdowns.value = response.data;
        };


        const getAnalyticAccounts = async () => {

            var response =  (vehicleMaintenanceData.value.analytic_account_type == 1) ?  await axios.get(ApiRoutes.analyticAccounts.list)
                                                                                      :  await axios.get(ApiRoutes.analyticAccountsIntra.list);

            analyticAccounts.value = response.data;


        };

        const dateFormat = (value) => {
            return moment(value).format('YYYY-MM-DD');
        };

        const getExecType = async (value) => {

            if (value == 1 ) {
                if (! props.vehicleMaintenance.supplier_employee_id) {
                    const element: HTMLElement = document.getElementById('select2-vehicleMaintenanceSupplierEmployeeId-container') as HTMLElement
                    element.innerHTML = translate('search') + '...';
                }

            }else if (value == 2) {
               if (! props.vehicleMaintenance.employee_id) {
                    const element: HTMLElement = document.getElementById('select2-vehicleMaintenanceEmployeeId-container') as HTMLElement
                    element.innerHTML = translate('search') + '...';
                }
                getSuppliers();

            }

        };

        const getSuppliers = async () => {
           const response = await axios.get(ApiRoutes.suppliers.list);

           suppliers.value = response.data;

        };


        // updating the status of the request
        // this function is used when using rejected and accpeted btns
        const updateState = async (btn) => {

            let status = (btn == 'accepted') ? vehicleMaintenanceData.value.status = '1' : vehicleMaintenanceData.value.status = '2';

            // requestMaintenanceData.value.state = status;
            // formData.append('state',status);
            const savedBtn: HTMLElement = document.getElementById('kt_modal_edit_vehicle_maintenance_submit') as HTMLElement
            savedBtn.click();

        };



        const submit = (values, actions) => {
            errors.value = null;

            if (!submitButtonRef.value) {
                return;
            }

            // Disable button
            // submitButtonRef.value.disabled = true;
            // Activate indicator
            // submitButtonRef.value.setAttribute("data-kt-indicator", "on");

            if (isUpdate && props.vehicleMaintenance.id) Object.assign(vehicleMaintenanceData.value, {id: props.vehicleMaintenance.id});

             vehicleMaintenanceData.value.date_begin = dateFormat(vehicleMaintenanceData.value.date_begin);

              if(vehicleMaintenanceData.value.employee_id)
              {
                  vehicleMaintenanceData.value.employee_id = vehicleMaintenanceData.value.employee_id.id
              }

              if(vehicleMaintenanceData.value.supplier_employee_id)
              {
                  vehicleMaintenanceData.value.supplier_employee_id = vehicleMaintenanceData.value.supplier_employee_id.id
              }

              vehicleMaintenanceData.value.vehicle_id = props.vehicleMaintenance.vehicle_id;


            // disableSubmitButton(submitButtonRef);


            axios.post(ApiRoutes.vehicleMaintenances.updateOrCreate, vehicleMaintenanceData.value)
                .then((response: AxiosResponse) => {

                    // enableSubmitButton(submitButtonRef);

                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false;

                        submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    }

                    if (response.data && response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            hideModal(editVehicleMaintenanceModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    // if (submitButtonRef.value) {
                    //     submitButtonRef.value.disabled = false;

                    //     submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    // }

                    if (error.response.status == 422) {
                        errors.value = error.response.data.errors;
                    } else {
                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                    }
                });
        };

        onMounted(() => {
            // getAnalyticAccounts();

            getTypes();
            getBreakdowns();
            getAnalyticAccounts();
            getSuppliers();

            modal.value = document.getElementById('kt_modal_edit_vehicle_maintenance');

            if (modal.value) modal.value.addEventListener('hidden.bs.modal', function() {
                Object.assign(vehicleMaintenanceData.value, vehicleMaintenanceDefault);
            });

            if (modal.value) modal.value.addEventListener('shown.bs.modal', function() {
                isUpdate = false;
                // vehicleMaintenanceData.value.status = -1;
                const el: HTMLElement = document.getElementById('select2-vehicleMaintenanceEmployeeId-container') as HTMLElement
                el.innerHTML = translate('search') + '...';

                if (props.vehicleMaintenance) {
                    isUpdate = true;


                    vehicleMaintenanceData.value.status = props.vehicleMaintenance.status;


                    vehicleMaintenanceData.value.employee_id = props.vehicleMaintenance.employee_id;

                    vehicleMaintenanceData.value.supplier_employee_id = props.vehicleMaintenance.supplier_employee_id;

                    if (props.vehicleMaintenance.supplier_employee_id) {

                        const element: HTMLElement = document.getElementById('select2-vehicleMaintenanceEmployeeId-container') as HTMLElement
                        element.innerHTML = props.vehicleMaintenance.employee_id.text;

                    }



                    if (props.vehicleMaintenance.supplier_employee_id) {

                        const sup: HTMLElement = document.getElementById('select2-vehicleMaintenanceSupplierEmployeeId-container') as HTMLElement
                        sup.innerHTML = props.vehicleMaintenance.supplier_employee_id.text;

                    }



                    vehicleMaintenanceData.value.analytic_account_type = props.vehicleMaintenance.analytic_account_type;
                    getAnalyticAccounts();

                    props.vehicleMaintenance.date = dateFormat(props.vehicleMaintenance.date);
                    props.vehicleMaintenance.expires = dateFormat(props.vehicleMaintenance.expires);

                    Object.keys(props.vehicleMaintenance).forEach((key) => {
                        if (vehicleMaintenanceDefault.hasOwnProperty(key)) vehicleMaintenanceData.value[key] = props.vehicleMaintenance[key];
                        // {
                            // if (typeof vehicleMaintenance[key] == 'boolean') vehicleMaintenanceData.value[key] = Boolean(props.vehicleMaintenance[key]);
                            // else

                        // }
                    });
                }
            });

            // load jquery for employee_id !!!

            jQuery("#vehicleMaintenanceEmployeeId").select2({
                dropdownParent: modal.value,
                minimumInputLength: 1,
                placeholder: translate('search') + '...',
                ajax: {
                    dataType: 'json',
                    delay: 250,
                    url: ApiRoutes.employees.employees,
                    data: function (params) {
                        return {
                            q: params.term,
                            code:'D01',
                            // exclude: requestMaintenanceOrderData.value.supplier_employee.map(a => a.id),
                        };
                    },
                    processResults: function (data, params) {
                        return {
                            results: data
                        };
                    },
                    cache: false
                }

            }).on('select2:select', function(e){
                let emp = e.params.data;
                vehicleMaintenanceData.value.employee_id = emp;
                let data = {
                    id              : emp.id,
                    heading         : null,
                    name            : emp.last_name,
                };
                    vehicleMaintenanceData.value.employee_id.push(data);


                jQuery("#vehicleMaintenanceEmployeeId").val('').trigger('change');
            });
            // end load jquery for employee_id


            // load jquery for supplier_employee_id !!!

            jQuery("#vehicleMaintenanceSupplierEmployeeId").select2({
                dropdownParent: modal.value,
                minimumInputLength: 1,
                placeholder: translate('search') + '...',
                ajax: {
                    dataType: 'json',
                    delay: 250,
                    url: ApiRoutes.employeesSupplier.list,
                    data: function (params) {
                        return {
                            q: params.term,
                            id:vehicleMaintenanceData.value.supplier_id,
                        };
                    },
                    processResults: function (data, params) {
                        return {
                            results: data
                        };
                    },
                    cache: false
                }

            }).on('select2:select', function(e){
                let emp = e.params.data;
                vehicleMaintenanceData.value.supplier_employee_id = emp;
                let data = {
                    id              : emp.id,
                    heading         : null,
                    name            : emp.last_name,
                };
                    vehicleMaintenanceData.value.supplier_employee_id.push(data);


                jQuery("#vehicleMaintenanceSupplierEmployeeId").val('').trigger('change');
            });
            // end load jquery for supplier_employee_id









        });

        return {
            errors,
            resetField,
            checkField,

            translate,
            dateFormat,
            types,
            breakdowns,
            suppliers,
            modal,
            analyticAccounts,
            getAnalyticAccounts,
            getSuppliers,
            updateState,
            getExecType,
            vehicleMaintenanceData,
            validationSchema,
            submit,
            submitButtonRef,
            editVehicleMaintenanceModalRef,
        };
    },
});
