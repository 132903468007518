
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import axios, { AxiosResponse } from "axios";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiRoutes from "@/core/config/ApiRoutes";

export default defineComponent({
    name: "services-fleet-vehicle-edit",
    emit: ["refreshData"],
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    setup() {
        const { t, te } = useI18n();
        const store = useStore();

        let isUpdate = false as boolean;
        let types = ref([]);
        let brands = ref([]);
        let colors = ref([]);
        let models = ref([]);
        let fuels = ref([]);
        let gearboxes = ref([]);
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editVehicleModalRef = ref<null | HTMLElement>(null);
        const vehicleDefaultData = {
            id: null,
            type_id: 0,
            brand_id: 0,
            model_id: 0,
            external: 0,
            color_id: 0,
            fuel_id: 0,
            gearbox_id: 0,
            vin: "",
            epc: "",
            year: null,
            vinPlaceholder: "",
            vrn: [],
            note: "",
        }
        const vehicleData = ref<any>({});
        Object.assign(vehicleData.value, vehicleDefaultData);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const validationSchema = Yup.object().shape({
            type_id: Yup.number().min(1).label(translate("vehicleTypeIdLabel")),
            brand_id: Yup.number().min(1).label(translate("vehicleBrandIdLabel")),
            model_id: Yup.number().min(1).label(translate("vehicleModelIdLabel")),
            year: Yup.number().min((new Date().getFullYear()) - 80).max((new Date().getFullYear()) + 1).label(translate("vehicleYearLabel")),
            external: Yup.boolean().label(translate("vehicleExternalLabel")),
            color_id: Yup.number().min(1).label(translate("vehicleColorIdLabel")),
            fuel_id: Yup.number().min(1).label(translate("vehicleFuelIdLabel")),
            gearbox_id: Yup.number().min(1).label(translate("vehicleTypeIdLabel")),
            vin: Yup.string().required().label(translate("vehicleVinLabel")),
            epc: Yup.string().required().label(translate("vehicleEpcLabel")),
            vrn: Yup.array().required().label(translate("vehicleVrnLabel")),
        });

        const submit = (values, actions) => {
            if (!submitButtonRef.value) {
                return;
            }

            addVrn();

            // Disable button
            submitButtonRef.value.disabled = true;
            // Activate indicator
            submitButtonRef.value.setAttribute("data-kt-indicator", "on");

            axios.post(ApiRoutes.vehicles.updateOrCreate, vehicleData.value)
                .then((response: AxiosResponse) => {

                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false;

                        submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    }

                    if (response.data && response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Confirmer",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            store.dispatch(Actions.REFRESH_VEHICLES, (isUpdate) ? 2 : 1);
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false;

                        submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    }

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const getTypes = async () => {
            const response = await axios.get(ApiRoutes.vehicles.types);

            types.value = response.data;
        };

        const getBrands = async () => {
            const response = await axios.get(ApiRoutes.vehicles.brands);

            brands.value = response.data;
        };

        const getModels = async () => {
            const response = await axios.get(ApiRoutes.vehicles.models, { params: { brand_id: vehicleData.value.brand_id } });

            models.value = response.data;
        };

        const getFuels = async () => {
            const response = await axios.get(ApiRoutes.vehicles.fuels);

            fuels.value = response.data;
        };

        const getColors = async () => {
            const response = await axios.get(ApiRoutes.vehicles.colors);

            colors.value = response.data;
        };

        const getGearboxes = async () => {
            const response = await axios.get(ApiRoutes.vehicles.gearboxes);

            gearboxes.value = response.data;
        };

        const addVrn = () => {

            if (vehicleData.value.vinPlaceholder != "") {
                vehicleData.value.vrn.push(vehicleData.value.vinPlaceholder);
                vehicleData.value.vinPlaceholder = "";
            }
        };

        const removeVrn = (index) => {
            vehicleData.value.vrn.splice(index, 1);
        };

        store.watch(
            (state, getters) => getters.getVehicle,
            (vehicle) => {

                if (vehicle) {
                    isUpdate = true;

                    Object.keys(vehicle).forEach((key) => {
                        if (vehicleDefaultData.hasOwnProperty(key)) vehicleData.value[key] = vehicle[key];
                    });

                    getModels();
                } else {
                    isUpdate = false;

                    Object.assign(vehicleData.value, vehicleDefaultData);
                }
            });

        onMounted(() => {
            getTypes();
            getBrands();
            getColors();
            getFuels();
            getGearboxes();
        });

        return {
            translate,
            types,
            brands,
            models,
            colors,
            fuels,
            gearboxes,
            vehicleData,
            getModels,
            addVrn,
            removeVrn,
            validationSchema,
            submit,
            submitButtonRef,
            editVehicleModalRef,
        };
    },
});
